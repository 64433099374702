import React from "react";

import { CheckMark } from "../../helpers/Icons";

const CancelationNotice = () => (
  <p className="text-center mb-4">
    <CheckMark size={18} color="var(--bs-primary)" />
    <span className="text-primary ms-1">
      Καμία δέσμευση. Καμία χρέωση ακύρωσης.{" "}
    </span>
    Μπορείτε να παραλείψετε ένα <br className="d-none d-lg-block" />
    γεύμα ή να ακυρώσετε τη συνδρομή σας online οποιαδήποτε στιγμή.
  </p>
);

export default CancelationNotice;
