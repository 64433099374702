import React from "react";

import { Placeholder } from "react-bootstrap";

const StripePlaceholder = () => {
  const labelHeight = "16px";
  const inputHeight = "38px";
  const textHeight = "13px";
  const backgroundColor = "var(--bs-gray-300)";

  return (
    <div aria-hidden="true">
      <div className="mb-3">
        <Placeholder
          as="p"
          animation="wave"
          className="mb-2 rounded-1"
          style={{
            width: "20%",
            height: labelHeight,
            backgroundColor,
          }}
        />
        <Placeholder
          as="p"
          animation="wave"
          className="rounded-1"
          style={{ height: inputHeight, backgroundColor }}
        />
      </div>
      <div className="mb-3">
        <Placeholder
          as="p"
          animation="wave"
          className="mb-2 rounded-1"
          style={{
            width: "20%",
            height: labelHeight,
            backgroundColor,
          }}
        />
        <div className="d-flex">
          <Placeholder
            as="p"
            animation="wave"
            className="flex-fill mb-0 me-3 rounded-1"
            style={{ height: inputHeight, backgroundColor }}
          />
          <Placeholder
            as="p"
            animation="wave"
            className="flex-fill mb-0 rounded-1"
            style={{ height: inputHeight, backgroundColor }}
          />
        </div>
      </div>
      <div className="mb-3">
        <Placeholder
          as="p"
          animation="wave"
          className="mb-2 rounded-1"
          style={{
            width: "20%",
            height: labelHeight,
            backgroundColor,
          }}
        />
        <Placeholder
          as="p"
          animation="wave"
          className="rounded-1"
          style={{ height: inputHeight, backgroundColor }}
        />
      </div>
      <div className="mb-3">
        <Placeholder
          as="p"
          animation="grow"
          className="rounded-1 mb-1"
          style={{ height: textHeight, backgroundColor }}
        />
        <Placeholder
          as="p"
          animation="grow"
          className="rounded-1"
          style={{ width: "70%", height: textHeight, backgroundColor }}
        />
      </div>
    </div>
  );
};

export default StripePlaceholder;
