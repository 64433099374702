import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useCart } from "react-use-cart";

import { Alert, Button, Form, Spinner } from "react-bootstrap";

import StripePlaceholder from "./placeholders/StripePlaceholder";
import { Currency } from "../../helpers";
import { isBrowser } from "../../helpers/checkEnvironment";
import { siteUrl } from "../../../config/site-config";

const CheckoutForm = () => {
  const { metadata } = useCart();
  const box = metadata.box || null;

  const [email] = useState(metadata.customer?.email);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // Initialize an instance of stripe and elements.
  const stripe = useStripe();
  const elements = useElements();

  const handleCheckout = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    const returnOrigin = isBrowser ? `${window.location.origin}/` : siteUrl;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${returnOrigin}status`,
      },
    });

    setLoading(false);

    if (error) {
      setMessage(error.message);
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="checkoutEmail">
        <Form.Label className="mb-0">Email </Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          value={email}
          readOnly
          style={{
            padding: "9px 12px",
            backgroundColor: "#fff",
            borderColor: "#e6e6e6",
            boxShadow:
              "0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)",
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {loadingForm && <StripePlaceholder />}
        <PaymentElement onReady={() => setLoadingForm(false)} />
      </Form.Group>
      <div className="mt-5 text-center">
        {message && <Alert variant="danger">{message}</Alert>}
        <Button
          type="submit"
          className="px-lg-6 fw-bold"
          onClick={handleCheckout}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                animation="grow"
                size="sm"
                variant="light"
                className="me-1"
              />{" "}
              ΣΕ ΕΞΕΛΙΞΗ...
            </>
          ) : (
            <>
              ΠΛΗΡΩΜΗ <Currency value={box?.price} />
            </>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default CheckoutForm;
