import React from "react";
import { navigate, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";
import { Elements } from "@stripe/react-stripe-js";

import Layout from "../components/common/Layout";
import CancelationNotice from "../components/elements/CancelationNotice";
import CheckoutForm from "../components/elements/CheckoutForm";
import { useShop } from "../context/ShopContext";
import { Currency } from "../helpers";
import { isBrowser } from "../helpers/checkEnvironment";
import { ArrowLeftBold } from "../helpers/Icons";
import { siteLang } from "../../config/site-config";

import "../styles/pages/checkout.scss";

const Checkout = () => {
  const { items, metadata } = useCart();
  const box = metadata?.box || null;
  const { logo, stripePromise } = useShop();

  if (!box && items.length < 1) {
    if (isBrowser) navigate("/", { replace: true });
    return null;
  }

  // Create line items - full details
  const cartItems = items.map((item) => {
    const { image } = item.media[0];

    return (
      <li className="checkout__line-item d-grid gap-2 mb-2 pb-2" key={item.id}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ? image.alt : ""}
        />
        <div className="fw-bold">
          <span className="checkout__line-item-title d-inline-block text-primary lh-1">
            {item.title}
          </span>
          <br />
          <small className="text-muted">
            Γεύματα{" "}
            <span className="fw-bold text-body">{item.quantity * 2}</span>
          </small>
        </div>
      </li>
    );
  });

  return (
    <Layout disableAnnouncement disableHeader disableFooter disableCartBar>
      <article className="checkout flex-fill d-lg-grid">
        <section className="d-flex flex-column mw-600 mx-auto py-4 py-md-6 px-3">
          <div className="flex-fill d-grid align-content-center">
            <Link to="/">
              <ArrowLeftBold />
              <GatsbyImage
                image={logo.gatsbyImageData}
                alt={logo.alt || ""}
                className="checkout__logo ms-3"
              />
            </Link>
            <div className="my-5 ms-md-5 ps-md-2">
              <h1 className="h4 mb-0 text-muted">Pay Hello Chef</h1>
              <h2 className="display-4 fw-normal mb-5">
                <span className="visually-hidden">Ποσό πληρωμής:</span>
                <Currency value={box.price} />
              </h2>
              <ul className="list-unstyled">{cartItems}</ul>
            </div>
          </div>
          <div>
            <CancelationNotice />
          </div>
        </section>
        <section className="d-flex flex-column py-6 px-3 bg-light">
          <div className="d-flex flex-column flex-fill mw-600 mx-auto">
            <div className="flex-fill d-grid align-content-center">
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: metadata.clientSecret,
                  locale: siteLang,
                  appearance: {
                    variables: {
                      colorPrimary: "#347e6e",
                    },
                  },
                }}
              >
                <CheckoutForm />
              </Elements>
            </div>
            <div className="text-center">
              H πιστωτική κάρτα θα χρεώνεται με το ποσό των{" "}
              <Currency value={box.price} /> κάθε εβδομάδα.
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Checkout;
